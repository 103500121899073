import React from 'react';
import { Link } from 'gatsby';
import style from './Footer.module.css';
import FooterLogo from '../../images/common/bi-logo-white.svg';

class Footer extends React.Component {
    render() {
        // let isIE = /*@cc_on!@*/false || !!document.documentMode;
        let isIE = false;

        return (
            <div className={style.FooterContainer}>
                <div className={style.FooterContent}>
                    <div className={( isIE ? style.FooterMainIE : style.FooterMain )}>
                        <div className={( isIE ? style.FooterImageContainerIE : style.FooterImageContainer )}>
                            <Link to="/"><img src={FooterLogo} alt="Footer Logo" className={style.FooterLogo} /></Link>
                        </div>
                        <ul className={( isIE ? style.FooterListIE : style.FooterList )}>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <Link to="/" className={style.FooterLink}>Home</Link>
                            </li>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <a href="https://www.boehringer-ingelheim.com.au/about-us" target="_blank" rel="noopener noreferrer" className={style.FooterLink}>About us</a>
                            </li>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <a href="https://www.boehringer-ingelheim.com.au/contact-form" target="_blank" rel="noopener noreferrer" className={style.FooterLink}>Contact us</a>
                            </li>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <Link to="/sitemap" className={style.FooterLink}>Sitemap</Link>
                            </li>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <a href="https://www.boehringer-ingelheim.com.au/terms-use" target="_blank" rel="noopener noreferrer" className={style.FooterLink}>Terms of use</a>
                            </li>
                            <li className={( isIE ? style.FooterListElementIE : style.FooterListElement)}>
                                <a href="https://www.boehringer-ingelheim.com.au/data-privacy" target="_blank" rel="noopener noreferrer" className={style.FooterLink}>Privacy policy</a>
                            </li>
                        </ul>
                    </div>
                    <div className={style.FooterPost}>
                        <p className={style.FooterPostText}>&copy; Boehringer Ingelheim Animal Health New Zealand Ltd. All rights reserved.</p>
                    </div>
                </div>
            </div>
        )
    }
}

export default Footer;
